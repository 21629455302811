import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import contactImg from "../assets/img/contact-img.svg"

const  Contact = () => {
    const formInitialDetails = {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        message: ''
    }

    const [formDetails, setFormDetails] = useState(formInitialDetails);
    const [buttonText,setButtonText] = useState('Send')
    const [status,setStatus] = useState({})

    const onFormUpdate = (category, value) => {
        setFormDetails({
            ...formDetails,
            [category]:value
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setButtonText('Sending...')
        //here the Logic of the sending data to email.
        setButtonText('Send')
        // take the response if it sent or not.
        setFormDetails(formInitialDetails)
        setStatus({ sucess: true, message: 'Message sent sucessfully'});
    }

  return (
    <section className="contact" id="connect">
        <Container>
            <Row className="aligb-items-center">
                <Col md={6}>
                    <img src={contactImg} alt="Contact Us"/>
                </Col>
                <Col md={6}>
                    <h2>Get In Touch</h2>
                    <form onSubmit={handleSubmit}>
                      <Row>
                        <Col sm={6} className="px-1">
                            <input type="text" value={formDetails.firstName} placeholder="First Name" onChange={(e) => onFormUpdate('firstName',e.target.value)} />
                        </Col>
                        <Col sm={6} className="px-1">
                            <input type="text" value={formDetails.lastName} placeholder="Last Name" onChange={(e) => onFormUpdate('lastName',e.target.value)} /> 
                        </Col>
                        <Col sm={6} className="px-1">
                            <input type="email" value={formDetails.email} placeholder="Email" onChange={(e) => onFormUpdate('email',e.target.value)} /> 
                        </Col>
                        <Col sm={6} className="px-1">
                            <input type="tel" value={formDetails.phone} placeholder="Phone No." onChange={(e) => onFormUpdate('phone',e.target.value)} /> 
                        </Col>
                        <Col>
                            <textarea row="6" value={formDetails.messagerm} placeholder="Message" onChange={(e) => onFormUpdate('message',e.target.value)} />
                            <button type="submit"><span>{buttonText}</span></button>
                        </Col>
                        {
                            status.message &&
                            <Col>
                               <p className={status.sucess === false ? "danger" : "sucess"}>
                                {status.message}
                                </p> 
                            </Col>
                        }
                      </Row>  
                    </form>
                </Col>
            </Row>

        </Container>
    </section>
  )
}

export default Contact