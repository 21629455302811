import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { ArrowRightCircle } from 'react-bootstrap-icons'
import headerImg from "../assets/img/header-img.svg"
import { useState,useEffect } from 'react'


export const Banner = ()=> {

    const [loopNum, setLoopNum] = useState(0);
    const [isDeleting, setIsDeleting] = useState(false);
    const toRotate = ["Sitecore 9.2 Developer", "Web Developer" ,"ML/AI Enthusiast"];
    const [text, setText] = useState('');
    const [delta,setDelta] = useState(300 - Math.random() * 100)
    const period = 2000;

    useEffect( ()=>{
        let ticker = setInterval (()=>{
           tick() 
        },delta)

        return ()=> {clearInterval(ticker)}
    },[text])

    const tick = () => {
        let i = loopNum % toRotate.length;
        let fullText = toRotate[i];
        let updateText = isDeleting ? fullText.substring(0, text.length-1) : fullText.substring(0, text.length + 1)

        setText(updateText);

        if(isDeleting){
            setDelta(prevDelta => prevDelta /2)
        }

        if(!isDeleting && updateText == fullText){
            setIsDeleting(true);
            setDelta(period);
        }else if(isDeleting && updateText === '') {
            setIsDeleting(false);
            setLoopNum(loopNum+1);
            setDelta(500)
        }
    }

  return (
    <section className="banner" id="home">
        <Container>
            <Row className="align-item-center">
                <Col xs={12} md={6} xl={7}>
                    <span className="tagline">Welcome to my Portfolio</span>
                    <h2>{`Hi I'm Divyansh Chaudhary`}<br/><span className='wrap'>{text}</span></h2>
                    <p>Lorem ipsum dolor sit amet. Ut suscipit quia ex velit explicabo quo harum obcaecati eos culpa amet sit sunt blanditiis ab omnis officiis! Sed voluptatum voluptatibus qui consequatur ipsam qui provident delectus et eaque consequatur ab voluptas vitae qui itaque voluptatem et esse porro. </p>
                    <p>Eum eligendi harum in molestias libero ea illum quaerat. Non voluptatum commodi qui beatae reprehenderit ad nihil perferendis quo excepturi accusantium? </p>
                    <p>In tempora accusantium eum repellat consequatur sed animi ullam 33 officia quod cum quod neque a quas unde. Qui sunt sint ex sapiente magnam aut modi rerum rem aperiam nulla in culpa eveniet. Ut pariatur voluptatibus sed veritatis natus aut iusto maxime. Ex exercitationem quos cum autem sequi At alias illum et culpa dolorem aut ipsum quaerat aut laudantium ipsa aut dolores voluptatum. </p>
                    <button onClick={() => console.log('connect')}>Let's connect <ArrowRightCircle size={25} /></button>
                </Col>
                <Col xs={12} md={6} xl={5}>
                    <img src={headerImg} alt="Header Img" />
                </Col>
            </Row>
        </Container>
    </section>
  )
}

export default Banner